import React from "react";

interface FAQItem {
  data: {
    title: string;
    description: string;
  };
}

const alpineTemplate = (body, title) => `


    <div x-data="{open: false}" @click="open=!open" class="flex flex-col w-full border-keyline border-b-2">
        <div class="flex flex-row justify-between  py-10">
            <div>
                    <div class="font-semibold text-xl pb-1">
                        ${title}

                    </div>
                <div x-show="open" x-transition:enter="transition ease-out duration-300" x-transition:enter-start="opacity-0  transform -translate-y-2" x-transition:enter-end="opacity-100 transform translate-y-0" x-transition:leave="transition ease-in duration-300" x-transition:leave-end="opacity-0 transform -translate-y-3" class="" style="display: none;">
                    <div class="w-20 border-primaryblack border-t-2 "></div>
                        <p class="py-2.5">
                           ${body}    <p></p>
                </div>
            </div>

            <div class="relative ml-4">
                <div class="w-5 lg:w-8 h-0.5 lg:h-1 mt-4 bg-black relative right-0"></div>
                <div class="w-5 lg:w-8 h-0.5 lg:h-1 mt-4 bg-black transform rotate-90 right-0 top-0 absolute" x-show="!open" x-transition:enter="transition transform rotate-180 ease-out duration-400" x-transition:enter-start=" transform rotate-180" x-transition:enter-end="transform rotate-90" x-transition:leave="transition ease-in duration-400" x-transition:leave-end=" transform rotate-180 ">
                </div>
            </div>
        </div>

    </div>

`;

export const FAQItem = ({ data }) => {
  return (
    <div
      className="faq-item"
      dangerouslySetInnerHTML={{
        __html: alpineTemplate(data.body, data.title),
      }}
    ></div>
  );
};
