import * as React from "react";
import { FAQItem } from "../components/FAQItem";
import { Header } from "../components/Header";
import { Layout } from "../components/Layout";

const faqs = [
  {
    title: "Will my policy still be in force now Reviti have stopped taking on new customers?",
    body: "Your Reviti Term Life policy will not be cancelled due to Reviti no longer distributing Life insurance policies. However, if you have any questions regarding the status of your policy you can contact Scottish Friendly who are the insurer of your policy on 0333 323 5433. Monday to Friday 8:30am - 5:30pm (excluding bank holidays)",
  },
  {
    title: "Who is the insurer of my policy?",
    body: "Scottish Friendly is the insurer for Reviti Term Life Insurance.",
  },

  {
    title: "Who is Scottish Friendly?",

    body: `Scottish Friendly is an insurance provider with over 150 years’ experience in providing financial products and services. They’re passionate about making insurance available to everyone – just like us. 
  I have more questions, who do I contact?
  For any change, payment or claim enquiries relating to an existing policy, please contact Scottish Friendly on 0333 323 5433, as they are the Life insurance provider of the Reviti Term life policies. Lines are open 8.30am to 5.30pm, Monday to Friday excluding bank holidays.
  Can I change my policy later?
  You can change details, like your address or your name after a marriage, at any time by contacting Scottish Friendly on 0333 323 5433. Lines are open 8.30am to 5.30pm, Monday to Friday excluding bank holidays. Your term or cover amount can’t be amended after you’ve completed your application but if you have any questions, please contact Scottish Friendly who will be able to talk to you about your options.
  `,
  },

  {
    title: " What happens when my policy term ends?",
    body: ` When your term ends, your policy ends. You’ll stop paying monthly premiums and you’ll no longer have cover. You’ll need to take out a new policy if you want to continue to be covered, as long as you are under the age of 80. 
  Is there a grace period during which I can change my mind?
  You have 30 days from the day you receive your policy documents to cancel your life insurance and you will receive a refund of any premiums you have paid, provided no claim has been made. You can cancel your policy by contacting Scottish Friendly on 0333 323 5433. Lines are open 8.30am to 5.30pm, Monday to Friday excluding bank holidays.
  `,
  },
  {
    title: "Can I cancel my policy at any time?",
    body: `Yes, you can cancel at any time by contacting Scottish Friendly on 0333 323 5433. Lines are open 8.30am to 5.30pm, Monday to Friday excluding bank holidays. If you do decide to cancel, or stop paying premiums for any reason, you won’t be eligible to claim and your insurance cover will end.
 `,
  },
  {
    title: "I have more questions, who do I contact?",
    body: `For any change, payment or claim enquiries relating to an existing policy, please contact Scottish Friendly on 0333 323 5433, as they are the Life insurance provider of the Reviti Term life policies. Lines are open 8.30am to 5.30pm, Monday to Friday excluding bank holidays.`,
  },
  {
    title: "Can I change my policy later?",
    body: `You can change details, like your address or your name after a marriage, at any time by contacting Scottish Friendly on 0333 323 5433. Lines are open 8.30am to 5.30pm, Monday to Friday excluding bank holidays. Your term or cover amount can’t be amended after you’ve completed your application but if you have any questions, please contact Scottish Friendly who will be able to talk to you about your options.`,
  },
  {
    title: "Is there a grace period during which I can change my mind?",
    body: `You have 30 days from the day you receive your policy documents to cancel your life insurance and you will receive a refund of any premiums you have paid, provided no claim has been made. You can cancel your policy by contacting Scottish Friendly on 0333 323 5433. Lines are open 8.30am to 5.30pm, Monday to Friday excluding bank holidays.`,
  },
];

const paymentFaqs = [
  {
    title: "  When and how often will I be charged?",
    body: `Your policy schedule will detail the premium payable each month during the policy term. Your monthly premium can only be paid by direct debit (you choose the payment date) and not by card or cheque. Therefore, please make sure you keep your direct debit live with your bank. 
  Your bank statement will show the premium being collected by ‘SFA’ (Scottish Friendly).`,
  },

  {
    title: "What payment methods can I use?",
    body: " Your monthly premium can only be paid by direct debit, not by card or cheque.",
  },

  {
    title: "Will my monthly premiums ever increase?",
    body: " Your premiums will never increase which is a positive reason to choose Reviti.",
  },

  {
    title: "What happens if I miss a payment?",
    body: ` If, for whatever reason, you stop paying your monthly premiums, your policy will be cancelled 30 days after the first missed payment was due. But if you make the payment you missed within that 30 day period and continue to pay your premiums, your policy will carry on. If your policy is cancelled, no claim can be made.
  `,
  },

  {
    title: " Can I update my payment details?",
    body: `Yes, by contacting Scottish Friendly on 0333 323 5433. Lines are open 8.30am to 5.30pm, Monday to Friday excluding bank holidays. `,
  },
];

const claimsFaq = [
  {
    title:
      "If I die or am diagnosed with a terminal illness, how is a claim made?",
    body: `  To make a claim for your death, your family or next of kin should contact  Scottish Friendly as soon as possible. They should do this within 6 months of your death. Scottish Friendly will send a claim form for them to complete and return.
  Scottish Friendly will need documentation such as a death certificate and proof of identity to ensure the right people are paid.
  If you have been diagnosed with a terminal illness, then you, or your next of kin, can contact Scottish Friendly as soon as possible. Scottish Friendly will require some reports from your doctors (and possibly specialists) and don’t worry, you won’t be charged for any of these reports. They will then check that your condition meets the definition as defined in the policy wording.
  Once all the documentation is received and the claim is approved, payment will be made in a matter of days. If your policy hasn’t been put in a Trust, Scottish Friendly will pay the lump sum to you (for terminal illness) or as directed by your personal representative for a death claim. If your policy was in Trust, then the proceeds would be paid in accordance with the Trust.
  `,
  },
  {
    title: " Are there any reasons the life insurance policy won’t be paid?",
    body: `  If within the first year of your policy, you die as a result of taking your own life, this policy will be cancelled and the lump sum will not be paid. However, Scottish Friendly will refund any premiums paid to your estate.
  If we find out that you or the person making a claim provided us or Scottish Friendly with false or incorrect information a claim may not be paid.
  `,
  },

  {
    title: "How is the payment claim handled?",
    body: "Once all the documentation is received and the claim is approved, payment will be made in a matter of days. If your policy hasn’t been put in a Trust, Scottish Friendly will pay the lump sum to you (for terminal illness) or as directed by your personal representative for a death claim. If your policy was in Trust, then the proceeds would be paid in accordance with the Trust.",
  },
  {
    title: " Is my lump sum subject to inheritance tax?",
    body: ` Depending on your financial circumstances, and current tax rules, and whether this policy is put in Trust at the time, the lump sum on death or diagnosis of terminal illness may be subject to tax.
  `,
  },
];

// markup
const IndexPage = () => {
  return (
    <Layout title={"Reviti Life Insurance for Smokers, Quitters and Switchers"}>
      {/*  */}
      <Header title={""} subtitle={""} currentPage={""} homePage={true} />

      <div className="container-padding relative flex flex-col md:flex-row my-30 terms-container">
        <div className={"w-full md:w-2/3 flex flex-col"}>
          <p className={" pt-20"}>
            <span className="article-section-heading">Reviti Life Insurance</span>
          </p>

          <p>
            <strong>Reviti Limited (Reviti) </strong>
            is no longer taking on new customers and has decided to cease
            distribution of the any <strong>Reviti </strong> Term Life insurance
            policies. <strong>Reviti </strong> Term Life insurance policies are
            insured by Scottish Friendly Assurance Society Limited (Scottish
            Friendly).
          </p>
          <p>
            If you require support with your existing <strong>Reviti </strong>{" "}
            Term Life insurance policy please contact Scottish Friendly.
            Scottish Friendly are the insurer for all <strong>Reviti </strong>{" "}
            Term Life policies, and they will administer all changes and claims
            for you.
          </p>
          <p>
            To make a claim, you, your family or next of kin should call <a href={"tel:03333235433"}>0333
            323 5433</a>.
          </p>
          <p>
            If you’re a <strong>Reviti </strong> customer and need to speak to
            someone about your policy, our insurance provider, Scottish
            Friendly, will take your call on <a href={"tel:03333235433"}>0333
            323 5433</a>. Monday to Friday
            8:30am - 5:30pm (excluding bank holidays)
          </p>
          <p>
            If you have a query regarding the <strong>Reviti </strong>
            offers when you purchased the policy, such as, quitting tobacco and
            nicotine altogether and therefore changing your smoker status or in
            regards to an Amazon.co.uk Gift Card, please email
            <a href={"mailto:support@revitilife.com"}>{" "}support@revitilife.com</a>.
          </p>
          <p>
            If you have an in force <strong>Reviti </strong> Term Life policy,
            you will receive an email outlining the support available and useful
            information soon.
          </p>
          <p>For more information please visit our FAQ’s listed below.</p>
          <p>Thank you</p>
          <p>
            The <strong>Reviti</strong>{" "} 
            team
          </p>
        </div>
        <hr />
      </div>
      <div className="container-padding  mx-auto  w-full section-header-large pt-30  ">
    
        <div className="border-none pb-30 lg:pb-50 mx-auto flex flex-col lg:flex-row items-start">
          <div className="w-full md:w-3/5 pr-0 md:pr-50 mt-5 md:my-5">
            <p>
              <span className="article-section-heading">
                You’ve got questions. We’ve got answers.
              </span>
            </p>
          </div>
          <div className="pt-0 md:pt-5  w-full md:w-2/5">
            <div className="my-2.5"> </div>{" "}
          </div>
        </div>
      </div>
      <div className="w-full container-padding mx-auto mb-20">
        <div className="w-full border-keyline border-t-2"></div>

        <div className="umb-block-list">
          {faqs.map((v) => (
            <FAQItem data={v} />
          ))}
          {paymentFaqs.map((v) => (
            <FAQItem data={v} />
          ))}
          {claimsFaq.map((v) => (
            <FAQItem data={v} />
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
